//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #000000; // The default text color

// $primary:    #6413F4;

// $primary:    #524CF2;


$primary: #8D1EFF;


$secondary: #6c757d;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  35: $spacer * 1.2,
  4: $spacer * 1.5,
  45: $spacer * 2,
  5: $spacer * 3,
  6: $spacer * 3.5,
  7: $spacer * 4,
  8: $spacer * 4.5,
  9: $spacer * 5,
  10: $spacer * 5.5,
);


// this function will create height class for the below values ex: h-100, h-80
$utilities: (
  "width": (property: width,
    class: w,
    responsive: true,
    values: (10:10%,
      15:15%,
      20:20%,
      25: 25%,
      30:30%,
      35:35%,
      40:40%,
      45:45%,
      65:65%,
      50: 50%,
      75: 75%,
      80:80%,
      90:90%,
      95:95%,
      100: 100%,
      auto: auto))
);

$input-btn-padding-y: 0.5rem;