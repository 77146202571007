//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;
    font-style: normal;
    position: relative;
    height: 100%;
}


:root {
    --sk-border-radius: 1rem;
    --sk-text-custom-color: #0C1424;
    --sk-grey-bg: #F9FAFB;
    --swiper-theme-color: var(--bs-primary);
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";

@import "../../node_modules/locomotive-scroll/dist/locomotive-scroll.css";

$prefix: 'bs-';

.text-custom {
    color: var(--sk-text-custom-color);
}

.custom-border-radius {
    border-radius: var(--sk-border-radius);
}

.text-custom-light {
    color: #B0B8C8;
}

.navbar {
    --bs-navbar-nav-link-padding-x: 1rem;
}

.landing-page {
    .landing-page-content {
        height: 100vh;
        background: url('../../images/album/istockphoto-1003476144-1024x1024.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: var(--sk-border-radius);

        padding: 1.5rem 4rem;

        h1 {
            @extend .text-custom;
        }

        .desc {
            @extend .text-custom;
        }
    }
}

@media (max-width: 576px) {
    .landing-page {
        .landing-page-content {
            padding: 1.5rem 1rem;
            height: unset;

        }
    }
}


.btn-with-icon {
    --bs-btn-padding-x: 0.5rem;
    min-width: 200px;
    display: flex;
    align-items: center;

    span {
        margin-right: var(--bs-btn-padding-x);
        background-color: #fff;
        aspect-ratio: 1;
        display: inline-block;
        border-radius: 50%;
        padding: 6px;
        display: inline-grid;
        place-content: center;
    }
}

.milestones {
    .milestones-content {
        padding: 4rem 3rem;
        background-color: var(--bs-primary);
        border-radius: var(--sk-border-radius);
        color: #fff;
        position: relative;
        overflow: hidden;

        &::after,
        &::before {
            content: '';
            position: absolute;
            background: url('../../images/album/shapes.png');
            height: 100%;
            width: 50%;
            background-position: top;
            background-repeat: no-repeat;
        }

        &::after {
            bottom: 0%;
            left: -13%;
        }

        &::before {
            bottom: -19%;
            right: -26%;
            transform: rotate(205deg);
        }

        .counter {
            text-align: center;

            h2 {
                font-weight: bold !important;
            }

            p {
                text-transform: uppercase;
                margin-bottom: 0;
            }
        }
    }
}

.brands {
    .swiper-wrapper {
        align-items: center;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: baseline;
    }

    .swiper-slide img {
        // filter: grayscale(100%);
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.gradient-text {
    background: -webkit-linear-gradient(45deg, #ff82f3 0%, #7b13ff 50%, #400d64 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-title {
    width: fit-content;
    border: 1px solid var(--bs-primary);
    padding: 0.67rem 1rem;
    border-radius: 50rem;
    text-align: center;
}

.section-subtitle {
    @extend .text-custom;
}

.aboutus {
    h6 {
        @extend .section-title;
    }

    .aboutus-img {
        border-radius: var(--sk-border-radius);
        background-position: center;
        background-size: cover;
        object-fit: cover;
        background-repeat: no-repeat;
        min-height: 90vh;
    }

    .milestones-temp {
        margin-top: calc(var(--bs-gutter-x) * 1);

        .counter {
            text-align: center;

            h2 {
                color: var(--bs-primary);
            }

            p {
                @extend .text-custom-light;
                margin: 0;
            }
        }
    }
}

.services {

    .explore-icon {
        display: inline-grid;
        place-content: center;
        background: var(--bs-primary);
        height: 52px;
        aspect-ratio: 1;
        border-radius: 50rem;
    }

    .icon-box {
        height: 54px;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: var(--bs-primary);

        display: grid;
        place-content: center;

        margin-bottom: 2rem;
    }

    .service-card {
        background: url('../../images/album/f_shap.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        padding: 1.5rem;
        @extend .custom-border-radius;
        height: 100%;



        .services-content {
            height: calc(100% - 33.6px - 32px - 24px - 1.11px);
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }

        .chips-box {
            display: flex;
            align-items: center;
            // justify-content: space-around;
            flex-wrap: wrap;

            .chips {

                border: 1px solid var(--sk-grey-bg);
                width: fit-content;
                white-space: nowrap;
                color: #fff;
                padding: 8px 20px 8px 20px;
                font-size: 14px;

                border-radius: 50rem;
                background-color: #000;
            }
        }
    }


    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        max-width: 310px;
        height: 450px;

        &:nth-child(2n) .service-card {
            background-position: left;
        }

        &:nth-child(3n) .service-card {
            background-position: right;
        }

        &:nth-child(4n) .service-card {
            background-position: top;
        }
    }
}

.custom-bg {
    background-color: var(--sk-grey-bg);
}

.custom-bg-dark {
    background-color: var(--sk-text-custom-color);
}

.faq {
    .accordion {
        --bs-accordion-bg: #F9FAFB;
        --#{$prefix}accordion-active-bg: #F9FAFB;
        --#{$prefix}accordion-btn-bg: #fff;
        --#{$prefix}accordion-active-color: #000;
        --#{$prefix}accordion-border-width: 0;
        --bs-accordion-btn-focus-border-color: transparent;
        --bs-accordion-btn-focus-box-shadow: transparent;
    }
}

.testimonials {
    .swiper {
        width: 100%;
        height: 650px;

        // border: 1px solid red;
    }

    .swiper-slide {
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        object-fit: cover;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-position: center;
    }
}

$primary-color: var(--bs-primary);

#contact {
    padding: 4rem 0;
    // background: url(../assets/images/bg30.jpg) no-repeat center;
    background: linear-gradient(#fff, $primary-color);
    background-size: cover;
    position: relative;
}

#contact::before {
    content: '';
    // background-color: rgba(9, 98, 234, 0.3);
    // background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
}

#contact .container {
    position: relative;
    z-index: 10;
}

#contact h5 {
    margin-bottom: 66px;
    text-transform: uppercase;
}

#contact h6 {
    color: $primary-color;
    margin-bottom: 1.5rem;
}

#contact p a,
address {
    font-weight: 300;
    color: rgba(255, 255, 255, 0.4);
}

#contact p i {
    margin-right: 20px;
    color: #fff;
    font-size: 18px;
}

#contact p i:hover {
    color: $primary-color;
}

#footer {
    padding: 5rem 0;
    padding-bottom: 4rem;
    background-color: var(--bs-primary);
    @extend .text-custom;
}

#footer>* a {
    text-decoration: none;
    color: inherit;
}

#footer p {
    font-size: 15px;
}

#footer ul {
    padding-left: 0;
    list-style: none;
}

#footer ul li {
    display: table;
    margin: 18px 0;
    transition: all .3s ease-in-out;
    transition: 0.3s all ease;
    word-break: break-word;
}

#footer ul li .iconcontainer i {
    font-size: 16px;
}

#footer ul li:hover,
#footer ul li a:hover {
    color: #fff !important;
}

#footer .iconcontainer {
    display: table-cell;
    vertical-align: top;
    width: 40px;
    font-size: 18px;
    padding-top: 2px;
    color: #fff;
}

.intro {
    img {
        max-height: 500px;
        object-fit: contain;
        width: 100%;
    }

    .list-group {
        --#{$prefix}list-group-bg: transparent;
        --#{$prefix}list-group-border-color: transparent;
    }
}

section.breadcrumb {
    .breadcrumb-content {
        height: 70vh;
        width: 100%;
        background: url('../../images/album/contact-bg.jpg.webp');
        background-size: cover;
        background-position: center;
        position: relative;
        border-radius: var(--sk-border-radius);
        display: grid;
        place-content: center;

        .breadcrumb-content-item {
            z-index: 1;
        }

        .breadcrumb {
            --#{$prefix}breadcrumb-divider-color: #fff;
            --#{$prefix}breadcrumb-item-active-color: #fff;
        }

        h2 {
            color: #fff;
        }

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(var(--bs-primary-rgb), 0.1);
            border-radius: inherit;
            z-index: 0;
        }


    }
}

.brands-we-service {
    .brand-service {
        img {
            height: 100px;
            width: 100%;
            object-fit: contain;

        }
    }
}


.our-service-includes {
    .col a {
        text-decoration: none;
    }

    .card {
        @extend .custom-border-radius;

        .card-body {
            display: flex;
            align-items: center;
        }

        --#{$prefix}card-border-color: #dee2e6;
        --#{$prefix}card-title-color: var(--sk-text-custom-color);

        h6 {
            margin: 0;
        }

        .check-mark {
            margin-right: 1rem;
        }
    }
}

.service-contact {
    padding: 4rem 0;
    background: linear-gradient(#fff, $primary-color);
    background-size: cover;
    position: relative;
    min-height: 50vh;
}

.why-us-brand {
    .icon-box {
        height: 54px;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: var(--bs-primary);

        display: grid;
        place-content: center;

        margin-bottom: 2rem;
    }

    .service-card {
        background: url('../../images/album/f_shap.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        padding: 1.5rem;
        @extend .custom-border-radius;
        height: 100%;

        .services-content {
            h5 {
                margin-bottom: 1.5rem;
            }

        }
    }

    .row {

        .col {

            &:nth-child(2n) .service-card {
                background-position: left;
            }

            &:nth-child(3n) .service-card {
                background-position: right;
            }

            &:nth-child(4n) .service-card {
                background-position: top;
            }

            &:nth-child(4n) .service-card {
                background-position: bottom;
            }
        }

    }
}

.common-prob {
    .service-title {
        @extend .gradient-text;
    }

    .solution {
        p {
            color: rgb(0, 0, 0,0.5);
        }
    }
}

